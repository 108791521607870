.app {
    margin: 0;
}
nav {
    height: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
    background: rgb(13, 121, 93);
    overflow-x: auto;
}
nav > div > a {
    text-decoration: none;
    color: whitesmoke;
    font-weight: bold;
    align-items: center;
    display: flex;
    font-size: large;
}
.nav-spacer {
    height: 100%;
    width: min(92%, 760px);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.page-content-wrapper {
    width: 100%;
    height: calc(100% - 50px);
    justify-content: center;
    display: flex;
    position: absolute;
    overflow: auto;
}
.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    overflow: auto;
    width: min(92%, 760px);
}
.page-title {
    text-align: center;
    font-size: 2em;
    font-weight: bolder;
    margin-block: 12px;
    color: #d2d700;
}
h2 {
    text-align: center;
}
button {
    color: black;
}