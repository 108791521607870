.loading-icon {
    margin-top: 5%;
    text-align: center;
    fill: whitesmoke;
    width: 30%;
    height: 30%;
    max-width: 500px;
    max-height: 500px;
}
.loading-icon > svg {
    animation: loading-spin infinite 2s linear;
}
.loading-icon > h2 {
    color: whitesmoke;
}
@keyframes loading-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}