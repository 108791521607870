#root {
    background-color: #101010;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
* {
    color: ghostwhite;
    font-family: monospace;
}
.betrayal-red {
    background-color: #db0000;
}
.betrayal-purple {
    background-color: #990099;
}
.betrayal-green {
    background-color: #008700;
}
.betrayal-white {
    background-color: #a7a7a7;
}
.betrayal-blue {
    background-color: #1f1ff7;
}
.betrayal-yellow {
    background-color: #b4b402;
}
h1, h2 {
    color: #d2d500;
}